<template>
  <!-- <div :class="[flag? '': 'content-box']"> -->
  <!-- <div :class="[flag? '': 'container']"> -->
  <div class="content">
    <h4 class="global_head_title">项目添加/编辑</h4>
    <div class="content_row flex">
      <label class="info" for="">项目名称<span class="redS">*</span></label>
      <div class="content_right">
        <a-input v-model="data.name" size="large" placeholder="请输入项目名称" />
      </div>
    </div>
    <div class="content_row flex">
      <label class="info" for="">关联合同</label>
      <div class="content_right">
        <a-select v-model="data.orderid" class="ant-select-lg">
          <a-select-option :value="item.id" v-for="(item, index) in orderList" :key="index">{{ item.osn }}
          </a-select-option>
        </a-select>
      </div>
    </div>
    <div class="content_row flex">
      <label class="info" for="">项目开始时间<span class="redS">*</span></label>
      <div class="content_right">
        <a-date-picker v-model="createtime" :inputReadOnly="true" size="large" @change="onPickCTime" />
      </div>
    </div>
    <div class="content_row flex">
      <label class="info" for="">项目结束时间<span class="redS">*</span></label>
      <div class="content_right">
        <a-date-picker v-model="endtime" :inputReadOnly="true" size="large" @change="onPickETime" />
      </div>
    </div>
    <div class="content_row flex">
      <label class="info" for="">客户<span class="redS">*</span></label>
      <div class="content_right">
        <a-select mode="multiple" size="large" ref="sel" v-model="client2" placeholder="请选择要添加的客户" :open="false"
          @focus="chooseClient" @change="clientChange">
          <!-- <a-select-option v-for="(item,index) in clientList" :key="index" :value="item.tmjcpb_corp__ids">
                {{ item.tmjcpb_corp__name }}
            </a-select-option> -->
        </a-select>
        <!-- <a-select show-search option-filter-prop="children" v-model="data.corpid" class="ant-select-lg">
          <a-select-option :value="item.tmjcpb_corp__ids" v-for="(item, index) in clientList" :key="index">
            {{ item.tmjcpb_corp__name }}</a-select-option>
        </a-select> -->
      </div>
    </div>
    <div class="content_row flex">
      <label class="info" for="">项目分类<span class="redS">*</span></label>
      <div class="content_right">
        <!-- <a-select v-model="data.project_type" class=" ant-select-lg">
                        <a-select-option :value="item.id" v-for="(item,index) in typeList" :key="index">{{item.name}}</a-select-option>
                    </a-select> -->
        <a-input v-model="data.project_type" size="large" placeholder="请输入项目分类" />
      </div>
    </div>
    <div class="content_row flex">
      <label class="info" for="">地址<span class="redS">*</span></label>
      <div class="content_right">
        <a-input v-model="data.address" size="large" placeholder="请输入地址" />
      </div>
    </div>
    <div class="content_row flex">
      <label class="info" for="">负责人<span class="redS">*</span></label>
      <div class="content_right">
        <a-input size="large" @focus="focus" :value="onStaff.name||''" @click="openStaff()" placeholder="请选择负责人" />
        <!-- <a-select @focus="focus" v-model="data.responsibility" @click="staffFlag=true" class="ant-select-lg">
          <a-select-option
            :value="item.userid"
            v-for="(item, index) in staff"
            :key="index"
            >{{ item.name }}</a-select-option
          >
        </a-select> -->
      </div>
    </div>
    <div class="content_row flex">
      <label class="info" for="">参与人<span class="redS">*</span></label>
      <div class="content_right">
        <a-select mode="multiple" show-search option-filter-prop="children" v-model="selTemp" class="ant-select-lg">
          <a-select-option :value="item.id" v-for="(item, index) in tempList" :key="index">{{ item.name }}
          </a-select-option>
        </a-select>
      </div>
    </div>
    <div class="content_row flex">
      <label class="info" for="">介绍人</label>
      <div class="content_right">
        <a-input v-model="data.introduce" size="large" placeholder="请输入介绍人" />
      </div>
    </div>

    <div class="content_row flex">
      <label class="info" for="">提成</label>
      <div class="content_right">
        <a-input prefix="￥" v-model="data.commission" size="large" placeholder="请输入提成" />
      </div>
    </div>
    <div class="content_row flex">
      <label class="info" for="">实际天</label>
      <div class="content_right">
        {{ data.actual_day }}
      </div>
    </div>
    <div class="content_row flex">
      <label class="info" for="">工人工资</label>
      <div class="content_right">￥ {{ data.actual_money }}</div>
    </div>
    <div class="content_row flex">
      <label class="info" for="">应收价格</label>
      <div class="content_right">
        <a-input prefix="￥" v-model="data.originally" size="large" placeholder="请输入应收价格" />
      </div>
    </div>
    <div class="content_row flex">
      <label class="info" for="">回款价格</label>
      <div class="content_right">
        <a-input prefix="￥" v-model="data.payment_money" size="large" placeholder="请输入回款价格" />
      </div>
    </div>
    <div class="content_row flex">
      <label class="info" for="">回款物品</label>
      <div class="content_right">
        <a-input v-model="data.payment_goods" size="large" placeholder="请输入回款物品" />
      </div>
    </div>
    <div class="content_row flex">
      <label class="info" for="">是否完结</label>
      <div class="content_right">
        <a-select v-model="data.status" class="ant-select-lg">
          <a-select-option :value="1">未完结</a-select-option>
          <a-select-option :value="2">已完结</a-select-option>
        </a-select>
      </div>
    </div>
    <div class="content_row flex">
      <label class="info" for="">备注</label>
      <div class="content_right">
        <a-textarea v-model="data.remarks" placeholder="请输入任务描述" :auto-size="{ minRows: 5, maxRows: 8 }" />
      </div>
    </div>

    <div class="save">
      <a-button type="primary" @click="submit">保存</a-button>
    </div>
    <staff :staffFlag="staffFlag" @updateStaffFlag="updateStaffFlag"></staff>
    <client :clientFlag="clientFlag" :clientMode="clientMode" @updateClientFlag="updateClientFlag"></client>
  </div>

  <!-- </div> -->
  <!-- </div> -->
</template>
<script>
// import $ from "jquery";
import Staff from "../../../components/staff.vue";
import Client from "../../../components/client.vue";
import {
  isMobile,
  ajaxUrl,
  requestXml,
  getDateTime,
} from "../../../assets/js/request";
export default {
  components: {
    Staff,
    Client,
  },
  data() {
    return {
      flag: true,
      id: "",
      ajaxUrl: ajaxUrl,
      staff: "", //员工列表
      skillid: "", //技能列表
      staffFlag: false,
      clientFlag: false,
      clientMode: 0,
      client2: [],
      onStaff: {
        name: "",
        userid: "",
      },
      data: {
        name: "",
        orderid: "",
        createtime: "",
        endtime: "",
        corpid: "",
        project_type: "",
        address: "",
        responsibility: "",
        introduce: "",
        commission: "",
        actual_day: "",
        actual_money: "",
        payment_money: "",
        payment_goods: "",
        originally: "",
        status: 1,
        remarks: "",
      },
      createtime: "", //开始时间
      endtime: "", //结束时间
      selSkillid: "", //技能选项
      clientList: "", //客户列表
      orderList: "", //合同列表
      tempList: "", //工人列表
      selTemp: undefined, //已选工人数组（参与人）
      typeList: "", //项目分类数组
    };
  },
  mounted: function () {
    this.id = this.$route.query.id;
    // this.getStaff();
    this.clientMode=1;
    this.getClient();
    this.getOrder();
    this.getTemp();
    // this.getType();
    if (this.id) {
      this.getDetail();
    }
    if (isMobile()) {
      this.flag = true;
    } else {
      this.flag = false;
    }
  },

  methods: {
    submit() {
      let url = "/jieb/Project/projectadd";
      if (this.id) {
        url = "/jieb/Project/projectedit";
        this.data.id = this.id;
      }
      if (this.selTemp) {
        this.data.json = this.selTemp.join(",");
      }
      this.data.responsibility = this.onStaff.userid;
      delete this.data.responsibility_name;
      delete this.data.corpname;
      requestXml(
        url,
        "POST",
        (res) => {
          console.log(res);
          if (res != "error") {
            if (!this.id) {
              this.$message.success("成功");
            }
            let path = "/scrm_pc/temp/itemList";
            if (isMobile()) {
              path = "/scrm_wap/item/itemList";
            }
            this.$router.push({
              path: path,
            });
          }
        },
        this.data
      );
    },
    clientChange(e){
      console.log(e);
      this.data.corpid='';
    },
    chooseClient() {
      this.clientFlag = true;
      this.$refs.sel.blur();
    },
     updateClientFlag(data) {
      // this.client = [];
      // this.client2 = [];

      if(data&&data!=1){ 
        this.data.corpid = data[0].tmjcpb_corp__ids;
       this.client2 = data[0].tmjcpb_corp__name;
      }
      // for(let i=0;i<data.length;i++){
      //     this.client.push(data[i].tmjcpb_corp__ids);
      //     this.client2.push(data[i].tmjcpb_corp__name);
      //     // let obj = {"tmjcpb_corp__ids":data[i].tmjcpb_corp__ids,"tmjcpb_corp__name":data[i].tmjcpb_corp__name};
      //     // this.client.push(obj);
      //     // console.log(this.client)
      //     // this.client2.push(data[i].tmjcpb_corp__name);
      // }
      this.clientFlag = false;
    },
    // 负责人-输入框聚焦是清除其焦点
    focus(e) {
      e.srcElement.blur();
    },
    openStaff() {
      this.staffFlag = true;
    },
    // 分配完
    updateStaffFlag(data) {
      // console.log(data,"dasds")
      if (data != 1) {
        this.onStaff = data;
      }
      this.staffFlag = false;
    },
    // 选择开始时间
    onPickCTime(date, dateString) {
      console.log(date);
      var time = new Date(dateString).getTime();
      this.data.createtime = Math.round(time / 1000);
    },
    onPickETime(date, dateString) {
      console.log(date);
      var time = new Date(dateString).getTime();
      this.data.endtime = Math.round(time / 1000);
    },
    // 获取合同数据
    getOrder() {
      requestXml(
        "/Scrm/Order/getMyList",
        "POST",
        (res) => {
          console.log(res);
          this.orderList = res.list;
        },
        { page: { curpage: 1, pagesize: 999999 } }
      );
    },

   
    // 获取客户列表
    getClient() {
      requestXml("/scrm/Corp/getUnBindCorp", "POST", (res) => {
        this.clientList = res;
        // console.log(res);
      });
    },
    // 获取分类
    getType() {
      requestXml("/jieb/Project/product", "POST", (res) => {
        this.typeList = res;
        // console.log(res);
      });
    },
    // // 获取技能分类
    // getSkillid(){
    //      requestXml("/jieb/Temporary/skillshow","POST",(res) => {
    //         this.skillid=res.list;
    //         // console.log(this.skillid);
    //         // console.log(JSON.stringify( this.skillid));
    //         if(this.id){
    //             this.getDetail();
    //         }
    //         // console.log(this.skillid);
    //     },{"page":{"curpage":1,"pagesize":999999}})
    // },
    getStaff() {
      requestXml("/scrm/Staff/getMinList", "POST", (res) => {
        this.staff = res;
      });
    },
    getTemp() {
      requestXml("/jieb/Project/tempbase", "GET", (res) => {
        this.tempList = res;
      });
    },
    // 获取详情
    getDetail() {
      requestXml(
        "/jieb/Project/projectedit",
        "GET",
        (res) => {
          console.log(res);
          this.data = Object.assign({}, res);
          if (this.data.orderid == 0) {
            this.data.orderid = "";
          }
          this.createtime = getDateTime(res.createtime);
          this.endtime = getDateTime(res.endtime);
          this.onStaff.name = res.responsibility_name;
          this.onStaff.userid = res.responsibility;
          if(res.corpname){this.client2 =res.corpname}
          // console.log(res);
          if (res.json) {
            this.selTemp = res.json.split(",");
            for (let i in this.selTemp) {
              this.selTemp[i] = Number(this.selTemp[i]);
              if (this.selTemp[i] == 0) {
                this.selTemp.splice(i, 1);
              }
            }
          }
        },
        { id: this.id }
      );
    },
  },
};
</script>
<style scoped>
.action {
  display: inline-block;
  padding: 5px 10px;
  margin: 0 5px;
  background: #dceeff;
  border: 1px solid #36a3ff;
  color: #36a3ff;
  border-radius: 5px;
}
.del {
  background: #ffdcdc;
  border-color: #ff4c4c;
  color: #ff4c4c;
}
.content .content_row {
  align-items: center;
  margin: 2rem 0;
}
.content_right {
  width: calc(100% - 120px);
  text-align: left;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #36a3ff;
  border-color: #36a3ff;
}
.content .content_row .info {
  display: inline-block;
  width: 15rem;
  text-align: right;
  padding-right: 1rem;
  color: #202020;
  font-weight: normal;
}
.content .content_row .fileLabel {
  line-height: 1.499;
  font-weight: 400;
  background-image: none;
  border: 1px solid #d9d9d9;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  cursor: pointer;
  height: 32px;
  line-height: 32px;
  padding: 5px 15px;
  font-size: 14px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.65);
}
.content .content_row input[type="file"] {
  display: none;
}
/* 输入框 下拉框 */
.content .content_row input,
.content .content_row .ant-select {
  width: 100%;
  /* background-color: #F9F9F9; */
}
.content .content_row .ant-select-selection--multiple input {
  width: 0;
}
/* 日期 */
.content .content_row .ant-calendar-picker {
  width: 100% !important;
}
.content .content_row .ant-calendar-picker input {
  width: 100%;
}

.save {
  text-align: left;
}
.save .ant-btn {
  width: 100px;
  height: 40px;
  background: #36a3ff;
  border-radius: 4px;
  margin-left: 13rem;
}

@media screen and (min-width: 750px) {
  .content .content_row {
    margin: 15px 0;
    font-size: 16px;
  }
  .content .content_row .info {
    width: 90px;
    padding-right: 10px;
  }
  .content .content_row .ant-calendar-picker {
    width: 400px !important;
  }
  .content .content_row .ant-calendar-picker input {
    width: 100% !important;
  }
  /* input ,selsct */
  .content .content_row input,
  .content .content_row .ant-select,
  .content .content_row textarea,
  .content .content_row .ant-input-affix-wrapper {
    width: 400px;
  }
  .save .ant-btn {
    margin-left: 90px;
  }
}
</style>
