<template>
    <div class="box">
        <Add></Add>
    </div>
    
</template>

<script>
import add from '../../scrm_pc/item/itemAdd'  
export default {
    components: {
        'Add': add  
    },
    data() {
        return {}
    },
    mounted: function(){},
}

</script>
<style>
    .box{
        height: 100vh;
        background-color: #fff !important;
        padding: 15px;
    }
</style>
